<template>
  <div>
    <div class="flex justify-between mb-8">
      <span>
        <strong>
          {{ $t("builder_config_payments.online_payments") }}
        </strong>
      </span>
      <b-switch v-if="isPro" v-model="isActivePaymentCfg" type="is-success" />
      <pro-label v-else size="small">
        {{ $t("builder_config_payments.pro_only") }}
      </pro-label>
    </div>
    <div class="flex w-full mb-8">
      <div class="card card-shadow rounded-md">
        <div class="card-content flex items-center jusity-center relative">
          <img class="animated-img" :src="imgStripeSrc" />
        </div>
      </div>
    </div>
    <b-field
      :label="$t('builder_config_payments.currency_code')"
      :custom-class="!isPro ? 'has-text-grey-light' : ''"
    >
      <b-select v-model="selectedCurrency" :disabled="disabledField" expanded>
        <option
          v-for="currency of formatedCurrencies"
          :key="`key-currency-${currency.value}`"
          :value="currency.value"
        >
          {{ currency.key }}
        </option>
      </b-select>
    </b-field>
    <b-field
      :label="$t('builder_config_payments.button_text')"
      :custom-class="!isPro ? 'has-text-grey-light' : ''"
    >
      <b-input
        v-model="textButtonPay"
        icon="credit-card"
        :placeholder="$t('builder_config_payments.button_text')"
        :disabled="disabledField"
      ></b-input>
    </b-field>
    <b-field
      :label="$t('builder_config_payments.public_key')"
      :custom-class="!isPro ? 'has-text-grey-light' : ''"
    >
      <b-input
        v-model="stripePublicKey"
        type="password"
        placeholder="pk_xxx_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx..."
        :disabled="disabledField"
      ></b-input>
    </b-field>
    <b-field
      :label="$t('builder_config_payments.private_key')"
      :custom-class="!isPro ? 'has-text-grey-light' : ''"
    >
      <b-input
        v-model="stripePrivateKey"
        placeholder="sk_xxx_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx..."
        type="password"
        :disabled="disabledField"
      ></b-input>
    </b-field>
  </div>
</template>
<style lang="scss" scoped>
.animated-img {
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.card-shadow {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(133, 133, 133, 0.5);
  -moz-box-shadow: 0px 0px 8px 0px rgba(133, 133, 133, 0.5);
  box-shadow: 0px 0px 8px 0px rgba(133, 133, 133, 0.5);
}
</style>
<script>
import ProLabel from "../../components/ProLabel.vue";
import ImgStripe from "@/assets/svg/stripe_logo.svg";
import ImgStripeGray from "@/assets/svg/stripe_logo_gray.svg";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    ProLabel
  },
  data() {
    return {
      imgStripe: "@/assets/svg/stripe_logo",
      imgPaypal: "@/assets/svg/paypal_logo_gray",
      classes: {
        inputLabel: ""
      },
      tmpStripePrivateKey: this.$store.state.builder.paymentCfg.stripeCfg
        .privateKey,
      tmpStripePublicKey: this.$store.state.builder.paymentCfg.stripeCfg
        .publicKey,
      labels: {
        selectCurrencyType: this.$t(
          "builder_config_payments.select_currency_type"
        )
      }
    };
  },
  computed: {
    ...mapGetters("builder", {
      currencies: "getCurrenciesPaymentsAvailables",
      getPaymentCfgProp: "getPaymentCfgProp",
      isActivePaymentCfgGetter: "isActivePaymentCfg"
    }),
    ...mapState("builder", ["isPro"]),
    disabledField() {
      return !this.isPro || (this.isPro && !this.isActivePaymentCfg);
    },
    imgStripeSrc() {
      return this.isActivePaymentCfg ? ImgStripe : ImgStripeGray;
    },
    formatedCurrencies() {
      return [
        {
          key: this.$t("builder_config_payments.select_currency_type"),
          value: ""
        },
        ...this.currencies
      ];
    },
    isActivePaymentCfg: {
      get() {
        return this.isActivePaymentCfgGetter;
      },
      set(val) {
        this.$store.commit(`builder/SET_PAYMENT_CONFIG_ACTIVE`, val);
      }
    },
    selectedCurrency: {
      get() {
        return this.getPaymentCfgProp("selectedCurrency");
      },
      set(val) {
        this.$store.commit(`builder/SET_PAYMENT_CONFIG_SELECTED_CURRENCY`, val);
      }
    },
    textButtonPay: {
      get() {
        return this.getPaymentCfgProp("textButtonPay");
      },
      set(val) {
        this.$store.commit("builder/SET_PAYMENT_CONFIG_TEXT_BUTTON_PAY", val);
      }
    },
    stripePublicKey: {
      get() {
        return this.getPaymentCfgProp("stripeCfg").publicKey;
      },
      set(val) {
        this.$store.commit("builder/SET_PAYMENT_CONFIG_STRIPE_PUBLIC_KEY", val);
      }
    },
    stripePrivateKey: {
      get() {
        return this.getPaymentCfgProp("stripeCfg").privateKey;
      },
      set(val) {
        this.$store.commit(
          "builder/SET_PAYMENT_CONFIG_STRIPE_PRIVATE_KEY",
          val
        );
      }
    }
  },
  watch: {
    stripePublicKey: function(value) {
      const changedValues = value !== this.tmpStripePublicKey;
      this.$store.commit("builder/SET_PAYMENT_CHANGED_KEYS", changedValues);
    },
    stripePrivateKey: function(value) {
      const changedValues = value !== this.tmpStripePrivateKey;
      this.$store.commit("builder/SET_PAYMENT_CHANGED_KEYS", changedValues);
    }
  }
};
</script>
